import React, { useState, useEffect } from 'react';
import './auth.css';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { signInSagaAction } from "../../redux/sagaActions";

const Auth = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    // Отправка формы авторизации
    const submit = () => {
        dispatch(signInSagaAction({ email, password, navigate }));
    };


    // Обработка нажатия клавиш
    const keydownHandler = event => {

        // Отправка формы при нажатии на Enter
        if (event.code === 'Enter') {
            submit();
        }
    };
    useEffect(() => {
        document.addEventListener('keydown', keydownHandler);

        return () => document.removeEventListener('keydown', keydownHandler);
    }, [email, password]);

    return (
        <div className="auth__wrapper">
            <div className="auth__scrollable">
                <div className="auth">
                    <div className="auth__title">Workouter</div>
                    <div className="auth__form">
                        <input
                            type="email"
                            className="auth__input auth__input--email"
                            placeholder={ 'Почта' }
                            value={ email }
                            onInput={ event => setEmail(event.target.value) }
                        />
                        <input
                            type="password"
                            className="auth__input auth__input--password"
                            placeholder={ 'Пароль' }
                            value={ password }
                            onInput={ event => setPassword(event.target.value) }
                        />
                        <i className="auth__submit" onClick={ submit } />
                    </div>
                    <div className="auth__buttons">
                        <div className="auth__button-wrapper auth__button-wrapper--center">
                            <div className="auth__button"
                                 onClick={ () => navigate('/') }
                            >
                                На главную страницу
                            </div>
                        </div>
                        <div className="auth__button-wrapper">
                            <div className="auth__button"
                                 onClick={ () => navigate('/recovery') }
                            >
                                Восстановить пароль
                            </div>
                            <div className="auth__button"
                                 onClick={ () => navigate('/registration') }
                            >
                                Зарегистрироваться
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Auth;
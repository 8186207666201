import { saga_types } from "./sagaTypes";

// Проверка токена
export const checkTokenSagaAction = data => ({ type: saga_types.CHECK_TOKEN_SAGA, data });
// Вход в систему
export const signInSagaAction = data => ({ type: saga_types.SIGN_IN_SAGA, data });
// Получение списка курсов
export const getCoursesListSagaAction = data => ({ type: saga_types.GET_COURSES_LIST_SAGA, data });
// Регистрация
export const registrationSagaAction = data => ({ type: saga_types.REGISTRATION_SAGA, data });
// Восстановление пароля
export const recoveryPasswordSagaAction = data => ({ type: saga_types.RECOVERY_PASSWORD_SAGA, data });
// Получение курса по id
export const getCourseByIdSagaAction = data => ({ type: saga_types.GET_COURSE_BY_ID_SAGA, data });
// Получение видео по id
export const getVideoByIdSagaAction = data => ({ type: saga_types.GET_VIDEO_BY_ID_SAGA, data });
// Покупка курса
export const buyCourseSagaAction = data => ({ type: saga_types.BUY_COURSE_SAGA, data });
import { takeLatest, call } from 'redux-saga/effects';
import { saga_types } from "../sagaTypes";
import { url } from "../../config";
import growl from "../../lib/growl/growl";

const request = data => {
    return fetch(`${ url }/recovery_password`, {
        method: 'post',
        body: data
    })
        .then(response => response.json()).catch(error => console.log(error))
};

function* workerRecoveryPassword(args) {
    try {
        let formData = new FormData();
        formData.append('email', args.data.email);

        const response = yield call (request, formData);

        if (response.success) {
            growl({ type: 'success', message: response.message });
            args.data.navigate(-1);
        }
        else if (response.error) {
            growl({ type: 'error', message: response.message });
        }
    }
    catch(error) {
        console.log(error);
    }
}

export default function* watcherRecoveryPassword() {
    yield takeLatest(saga_types.RECOVERY_PASSWORD_SAGA, workerRecoveryPassword);
}
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { registrationSagaAction } from "../../redux/sagaActions";

const Registration = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    return (
        <div className="form">
            <div className="form__scrollable">
                <div className="form__content">
                    <div className="form__header">
                        <div className="form__close" onClick={ () => navigate(-1) }/>
                        <div className="form__title">Регистрация</div>
                    </div>
                    <div className="form__field">
                        <div className="form__caption form__caption--required">Имя и фамилия</div>
                        <input type="text" className="input"
                               value={ name }
                               onInput={ event => setName(event.target.value) }
                        />
                    </div>
                    <div className="form__field">
                        <div className="form__caption form__caption--required">Телефон</div>
                        <input type="tel" className="input"
                               value={ phone }
                               onInput={ event => setPhone(event.target.value) }
                        />
                    </div>
                    <div className="form__field">
                        <div className="form__caption form__caption--required">Почта</div>
                        <input type="email" className="input"
                               value={ email }
                               onInput={ event => setEmail(event.target.value) }
                        />
                    </div>
                    <div className="form__field">
                        <div className="form__caption form__caption--required">Пароль</div>
                        <input type="password" className="input"
                               value={ password }
                               onInput={ event => setPassword(event.target.value) }
                        />
                    </div>
                    <div className="form__field">
                        <div className="button"
                            onClick={ () => dispatch(registrationSagaAction({
                                name,
                                phone,
                                email,
                                password,
                                navigate
                            })) }
                        >Сохранить</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Registration;
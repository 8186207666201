import React from 'react';
import './course.css';
import { useDispatch, useSelector } from "react-redux";
import { site } from "../../config";
import { buyCourseSagaAction, getVideoByIdSagaAction } from "../../redux/sagaActions";
import PlayableVideo from "../../components/PlayableVideo/PlayableVideo";

const Course = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const course = useSelector(state => state.course);
    const playable_video = useSelector(state => state.playable_video);

    return (
        <div className="course">
            { playable_video.id && <PlayableVideo/> }
            <div className="course__content">
                <div className="course__name-wrapper">
                    {
                        user.id && course.block &&
                        <div className="course__buy"
                            onClick={ () => dispatch(buyCourseSagaAction({ course_id: course.course_id })) }
                        >Купить курс</div>
                    }
                    <div className="course__name">{ course.name }</div>
                    <div className="course__description">{ course.description }</div>
                </div>
            </div>
            {
                course.videos.length > 0 &&
                <div className="course__videos">
                    {
                        course.videos.map(elem => {
                            return (
                                <div key={ elem.id } className={ `course__video ${ course.block ? 'course__video--block' : '' }` }
                                    onClick={ () => dispatch(getVideoByIdSagaAction({ course_id: course.course_id, video_id: elem.id })) }
                                >
                                    <div className="course__video-image">
                                        <div className="course__video-mask"/>
                                        <img className="course__video-img" src={ site + elem.preview } alt="" />
                                        <div className="course__video-duration">{ elem.duration }</div>
                                    </div>
                                    <div className="course__video-meta">
                                        <div className="course__video-name">{ elem.name }</div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            }
        </div>
    );
};

export default Course;
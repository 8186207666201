import { takeLatest, call } from 'redux-saga/effects';
import { saga_types } from "../sagaTypes";
import { url } from "../../config";
import growl from "../../lib/growl/growl";

const request = data => {
    return fetch(`${ url }/registration`, {
        method: 'post',
        body: data
    })
        .then(response => response.json()).catch(error => console.log(error))
};

function* workerRegistration(args) {
    try {
        let formData = new FormData();
        formData.append('name', args.data.name);
        formData.append('phone', args.data.phone);
        formData.append('email', args.data.email);
        formData.append('password', args.data.password);

        const response = yield call (request, formData);

        if (response.success) {
            growl({ type: 'success', message: response.message });
            args.data.navigate(-1);
        }
        else if (response.error) {
            growl({ type: 'error', message: response.message });
        }
    }
    catch(error) {
        console.log(error);
    }
}

export default function* watcherRegistration() {
    yield takeLatest(saga_types.REGISTRATION_SAGA, workerRegistration);
}
import React, { useEffect } from 'react';
import './playableVideo.css';
import { useDispatch, useSelector } from "react-redux";
import { site } from "../../config";
import { setPlayableVideoAction } from "../../redux/rootReducer";
import { PLAYABLE_VIDEO_INITIAL } from "../../redux/initialStateConstant";

const PlayableVideo = () => {
    const dispatch = useDispatch();
    const playable_video = useSelector(state => state.playable_video);

    useEffect(() => {
        document.querySelector('video').addEventListener('contextmenu', event => event.preventDefault());
    }, []);

    return (
        <div className="playable">
            <div className="playable__scrollable">
                <div className="playable__content">
                    <div className="playable__close" onClick={ () => dispatch(setPlayableVideoAction(PLAYABLE_VIDEO_INITIAL)) }/>
                    <div className="playable__video">
                        <video src={ site + playable_video.link } controls={ true } autoPlay={ true } controlsList={ 'nodownload' } />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PlayableVideo;
import React from 'react';
import './buy.css';
import { NavLink } from "react-router-dom";

const BuyError = () => {
    return (
        <div className="buy buy--error">
            <div className="buy__scrollable">
                <div className="buy__content">
                    <div className="buy__status">
                        <div className="buy__ico"/>
                        <div className="buy__text">При оплате курса прошла ошибка. Пожалуйста, попробуйте снова.</div>
                    </div>
                    <div className="buy__redirect-wrapper">
                        <NavLink to={ '/courses' } className="buy__redirect">На страницу с курсами</NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BuyError;
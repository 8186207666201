import React from 'react';
import './coursesItem.css';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { site } from "../../config";
import { getCourseByIdSagaAction } from "../../redux/sagaActions";

const CoursesItem = ({ data }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    return (
        <div className="course__item"
            onClick={ () => dispatch(getCourseByIdSagaAction({ course_id: data.id, navigate })) }
        >
            <div className="course__item-preview-wrapper">
                <img src={ site + data.preview } alt="" className="course__item-preview"/>
            </div>
            <div className="course__item-content">
                <div className="course__item-name">{ data.name }</div>
                <div className="course__item-meta">
                    <div className="course__item-meta-unit course__item-meta-unit--level">{ data.level }</div>
                    <div className="course__item-meta-unit course__item-meta-unit--duration">{ data.duration }</div>
                </div>
                <div className="course__item-description">{ data.description }</div>
            </div>
        </div>
    );
};

export default CoursesItem;
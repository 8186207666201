import { configureStore } from '@reduxjs/toolkit'
import { reducer } from './rootReducer'
import createSagaMiddleware from 'redux-saga';

import watcherCheckToken from "./sagas/checkToken";
import watcherSignInSubmit from "./sagas/signInSaga";
import watcherGetCoursesList from "./sagas/getCoursesListSaga";
import watcherRegistration from "./sagas/registrationSaga";
import watcherRecoveryPassword from "./sagas/recoveryPasswordSaga";
import watcherGetCourseById from "./sagas/getCourseByIdSaga";
import watcherGetVideoById from "./sagas/getVideoByIdSaga";
import watcherBuyCourse from "./sagas/buyCourseSaga";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: reducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }).concat(sagaMiddleware)
});

export default store;

sagaMiddleware.run(watcherCheckToken);
sagaMiddleware.run(watcherSignInSubmit);
sagaMiddleware.run(watcherGetCoursesList);
sagaMiddleware.run(watcherRegistration);
sagaMiddleware.run(watcherRecoveryPassword);
sagaMiddleware.run(watcherGetCourseById);
sagaMiddleware.run(watcherGetVideoById);
sagaMiddleware.run(watcherBuyCourse);
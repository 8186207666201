import React from 'react';
import './main.css';

const Main = () => {
    return (
        <main className="main">
            <div className="main__title">workouter.by</div>
            <div className="main__caption">Сервис позволяющий покупать обучающие видео курсы и заниматься в удобное для вас время, в удобном для вас месте.</div>
        </main>
    );
};

export default Main;
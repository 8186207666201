// Начальное состояние пользователя
export const USER_INITIAL = {
    id: '',
    name: '',
    hash: ''
};
// Начальное состояние курса
export const COURSE_INITIAL = {
    course_id: '',
    name: '',
    videos: []
};
// Воспроизводимое видео
export const PLAYABLE_VIDEO_INITIAL = {
    id: '',
    name: '',
    link: ''
};
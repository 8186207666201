import { takeLatest, put, call, select } from 'redux-saga/effects';
import { saga_types } from "../sagaTypes";
import { url } from "../../config";
import growl from "../../lib/growl/growl";

const request = data => {
    return fetch(`${ url }/buy_course`, {
        method: 'post',
        body: data
    })
        .then(response => response.json()).catch(error => console.log(error))
};

function* workerBuyCourse(args) {
    const user = yield select(state => state.user);
    try {
        let formData = new FormData();
        formData.append('user_id', user.id);
        formData.append('hash', user.hash);
        formData.append('course_id', args.data.course_id);

        const response = yield call (request, formData);

       if (response.success) {
           window.open(response.link, '_blank').focus();
       }
        else if (response.error) {
            growl({ type: 'error', message: response.message });
        }
    }
    catch(error) {
        console.log(error);
    }
}

export default function* watcherBuyCourse() {
    yield takeLatest(saga_types.BUY_COURSE_SAGA, workerBuyCourse);
}
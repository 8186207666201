import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './assets/fonts/onest/font.css';
import './assets/styles/reset.css';
import './assets/styles/variables.css';
import './assets/styles/common.css';
import './assets/styles/form.css';
import { Provider } from "react-redux";
import store from "./redux/store";
import './lib/growl/growl.css';

const root = ReactDOM.createRoot(document.querySelector('.layout'));
root.render(
    <Provider store={ store }>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </Provider>
);
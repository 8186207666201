import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { recoveryPasswordSagaAction } from "../../redux/sagaActions";

const Recovery = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');

    return (
        <div className="form">
            <div className="form__scrollable">
                <div className="form__content">
                    <div className="form__header">
                        <div className="form__close" onClick={ () => navigate(-1) }/>
                        <div className="form__title">Восстановление пароля</div>
                    </div>
                    <div className="form__field">
                        <div className="form__caption form__caption--required">Укажите почту, введённую при регистрации</div>
                        <input type="email" className="input"
                               value={ email }
                               onInput={ event => setEmail(event.target.value) }
                        />
                    </div>
                    <div className="form__field">
                        <div className="button"
                             onClick={ () => dispatch(recoveryPasswordSagaAction({ email, navigate })) }
                        >Выслать пароль</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Recovery;
import { takeLatest, put, call, select } from 'redux-saga/effects';
import { saga_types } from "../sagaTypes";
import { setPlayableVideoAction } from "../rootReducer";
import { url } from "../../config";
import growl from "../../lib/growl/growl";

const request = data => {
    return fetch(`${ url }/get_video_by_id`, {
        method: 'post',
        body: data
    })
        .then(response => response.json()).catch(error => console.log(error))
};

function* workerGetVideoById(args) {
    const user = yield select(state => state.user);
    try {
        let formData = new FormData();
        formData.append('user_id', user.id);
        formData.append('hash', user.hash);
        formData.append('course_id', args.data.course_id);
        formData.append('video_id', args.data.video_id);

        const response = yield call (request, formData);

       if (response.success) {
            yield put(setPlayableVideoAction(response.video));
        }
        else if (response.error) {
            growl({ type: 'error', message: response.message });
        }
    }
    catch(error) {
        console.log(error);
    }
}

export default function* watcherGetVideoById() {
    yield takeLatest(saga_types.GET_VIDEO_BY_ID_SAGA, workerGetVideoById);
}
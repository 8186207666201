import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Preloader from "./components/Preloader/Preloader";
import { checkTokenSagaAction } from "./redux/sagaActions";
import Auth from "./pages/Auth/Auth";
import Main from "./pages/Main/Main";
import Courses from "./pages/Courses/Courses";
import Header from "./components/Header/Header";
import Registration from "./pages/Registration/Registration";
import Recovery from "./pages/Recovery/Recovery";
import Course from "./pages/Course/Course";
import BuySuccess from "./pages/BuyCourse/BuySuccess";
import BuyError from "./pages/BuyCourse/BuyError";

const App = () => {
    const dispatch = useDispatch();
    const loader = useSelector(state => state.loader);
    const startApp = useSelector(state => state.startApp);

    const router = createBrowserRouter([
        {
            path: '/',
            element:
                <>
                    <Header/>
                    <div className="content">
                        <Main />
                    </div>
                </>,
        },
        {
            path: '/auth',
            element: <Auth/>
        },
        {
            path: '/registration',
            element: <Registration/>
        },
        {
            path: '/recovery',
            element: <Recovery/>
        },
        {
            path: '/courses',
            element:
                <>
                    <Header/>
                    <div className="content">
                        <Courses />
                    </div>
                </>
        },
        {
            path: '/course',
            element:
                <>
                    <Header/>
                    <div className="content">
                        <Course />
                    </div>
                </>
        },
        {
            path: '/bepaid_transaction_success',
            element: <BuySuccess/>
        },
        {
            path: '/bepaid_transaction_decline',
            element: <BuyError/>
        }
    ]);

    useEffect(() => {
        dispatch(checkTokenSagaAction());
    }, []);

    if (!startApp) {
        return <Preloader/>
    }

    return (
        <div className="">
            { loader && <Preloader/> }
            <RouterProvider router={ router } />
        </div>
    );
};

export default App;
